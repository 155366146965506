:root {
    --doc-height: 100%;
    height: inherit !important;
}

html,
body {
 padding: 0;
 margin: 0;
 height: 100vh; /* fallback for Js load */
 height: var(--doc-height);
}